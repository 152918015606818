import React, {useEffect, useState} from 'react'
import GraphQLErrorList from '../../../components/graphql-error-list'

import Container from '../../../components/container'
import Seo from '../../../components/seo'
import Heading from '../../../components/heading'
import Layout from '../../../containers/layout'
import Summer2020 from '../../../components/summer-2020'
import Summer2020Form from '../../../components/summer-2020-form'
import Term1Closed from '../../../components/summer-2020-closed'

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout isHome>
      <Seo />
      <Container>
        <Heading ja={'サマースクール&キャンプ2020\n第一ターム'} en='Summer School & Camp 2020 1st Term' />
        <Term1Closed />
      </Container>
    </Layout>
  )
}

export default IndexPage
